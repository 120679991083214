import React from 'react'
import './DevBanner.css'

export default function DevBanner() {
  return (
    <div className="maintenance">
      <div className="maintenance-banner-overlay">
        <div className="maintenance-banner-content">
          <p className="maintenance-text-color">Sistema de Desenvolvimento | Sistema não produtivo</p>
        </div>
      </div>
    </div>
  )
}
